import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Layout, SEO } from '@global'
import { Hero } from '@layouts'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroData = data.hero.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero
        fluid={heroData}
        eyebrow="404: Page Not Found"
        title="Oops, there's no page at this url"
        ctaText="go home"
        ctaLink="/"
      />
    </Layout>
  )
}

export default NotFoundPage
